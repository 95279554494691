form {
    font-family: Helvetica;
    font-size: 20px;
    padding: 10px;
}

.fullForm {
    margin: 10px 40px;
}

.fullForm p {
    padding: 5px;
}

.radio {
    padding: 10px;
}

.cardDisplay {
    background-image: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    transition: 1s;
    margin-bottom: 50px;
}

.deckChoice {
    display: flex;
    justify-content: space-around;
    height: 200px;
}

.IndividualDeck  {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 175px;
    height: 180px;
    background-image: none;
}

.radio input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    top: 0;
    left: 0;
    height: 145px;
    border-radius: 15px;
    transition: 300ms;
    cursor: pointer;
}

.radio:hover input ~ .checkmark {
    margin-top: -25px;
    box-shadow: 0px -5px 7px rgba(0,0,0,0.9);
    transition: 300ms
}

.radio input:checked ~ .checkmark {
    box-shadow: 0px -3px 7px #D4AF37,
                3px 0px 7px #D4AF37,
                -3px 0px 7px #D4AF37;
    margin-top: -25px;
    transition: 250ms;
}

.cardPocket {
    display: flex;
    justify-content: center;
    z-index: 5;
    height: 60px;
    width: 115%;
    box-shadow: 0px -15px 10px -9px rgba(0,0,0,0.9),
                inset 0px 8px 8px -13px rgba(255,255,255,0.75);
    margin-top: 125px;
    position: absolute;
    text-align: center;
}

#thothPocket {
    width: 135%;
    height: 60px;
}

.cardPocket h2 {
    width: 160px;
    margin: 5px 0px;
    height: 60px;
}

.CardChoice {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 180px;
}

.CardChoice h2 {
    position: relative;
    margin-top: 190px;
}

@media only screen and (max-width: 600px) {
    .IndividualDeck {
        width: 25vw;
        margin: 10px;
    }
    .checkmark {
        height: 20vw;
    }
    .cardPocket {
        margin-top: 20vw;
        height: 8vh;
    }
    #thothPocket {
        height: 8vh;
    }
    .cardPocket h2 {
        font-size: 16px;
        height: 1vh;
    }
    .radio:hover input ~ .checkmark {
        margin-top: -2vw;
    }
    .radio input:checked ~ .checkmark {
        margin-top: -2vw;
    }
    .deckChoice {
        height: 21vh;
    }
    .CardChoice {
        height: 19vh;
    }
}

.MagicButton {
    background-image: none;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    z-index: 20;
}

.MagicButton button {
    background-image: none;
    cursor: pointer;
    border: 3px solid #D4AF37;
    box-shadow: 1px 1px 5px black,
                -1px -1px 5px black;
    height: 150px;
    width: 150px;
    border-radius: 150px;
    transition: 400ms;
    z-index: 20;
}

button:focus {
    border-radius: 150px;
    z-index: 20;
    outline: none;
}

.MagicButton h2 {
    background-color: transparent;
    background-image: none;
    font-size: 30px;
    margin: 0 auto;
    transition: 250ms;
    padding: 3px;
    z-index: 20;
}

.MagicButton:hover button {
    box-shadow: 2px 2px 20px #D4AF37,
                 -2px -2px 20px #D4AF37,
                70px 0px 100px -10px #D4AF37,
                -70px 0px 100px -10px #D4AF37,
                inset 0px 0px 40px #D4AF37;
    transition: 400ms;
    z-index: 20;
}


.MagicButton:hover h2 {
    text-shadow: 1px 0px 1px #D4AF37,
                 -1px -0px 1px #D4AF37;
    z-index: 20;
}

@media only screen and (max-width: 375px) {
    .MagicButton button {
        width: 120px;
        height: 120px;
        border-radius: 120px;
    }
    .MagicButton h2 {
        font-size: 22px;
    }
}